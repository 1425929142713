<template>
  <div>
    <main class="login">
      <div class="login-inner video-container">
        <video controls autoplay preload="auto">
          <source :src="videoUrl" type="video/mp4">
        </video>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    videoUrl() {
      return `https://myportal-media.s3.eu-west-2.amazonaws.com/${this.$route.params.folder}/${this.$route.params.filename}.${this.$route.params.format}`.toLowerCase();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  padding-top: 10rem;
  max-width: 92%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.login-inner {
  max-width: 100%;
}

.video-container {
  max-width: 100%;
  height: 100%;
}

.video-container video {
  max-width: 100%;
  height: 100%;
}
</style>
